<template>
  <div class="wrap">
    <Sticky>
      <Navbar :title="$t('page.member')" :border="false" />
      <div class="tab_wrap">
        <div class="content_wrap">
          <div class="tab_item" :class="tabNow === 0 ? 'active' : ''" @click="tabNow = 0">{{
              $t('memberDetails.tabs.teamInfo')
          }}</div>
          <div class="tab_item" :class="tabNow === 1 ? 'active' : ''" @click="tabNow = 1">{{
              $t('memberDetails.tabs.lower')
          }}</div>
        </div>
      </div>
    </Sticky>
    <div class="content" v-if="tabNow === 0">
      <ListItem :data="ownData" :showLink="false" :showSuper="false" :showToDetails="false" :route="false"
        :rightLower="true" />
      <CellGroup class="own_money_info_wrap">
        <div class="item">
          <p class="title">{{ $t('memberDetails.teamBalance') }}</p>
          <div class="money">{{ `${ownData.group_money || 0} ${verConfig.moneyType}` }}</div>
        </div>
        <div class="item">
          <p class="title">{{ $t('memberDetails.ownBalance') }}</p>
          <div class="money">{{ `${ownData.money || 0} ${verConfig.moneyType}` }}</div>
        </div>
      </CellGroup>
      <div class="btns_tabs_wrap">
        <div class="btn_item" :class="bottomTabNow === 'personal' ? 'active' : ''" @click="bottomTabChange('personal')">
          {{ $t('memberDetails.personalRecord') }}</div>
        <div class="btn_item" :class="bottomTabNow === 'group' ? 'active' : ''" @click="bottomTabChange('group')">{{
            $t('memberDetails.teamRecord')
        }}
        </div>
      </div>
      <div class="date_btns_wrap">
        <div class="btn_item" v-for="item, index in dateData" :key="`date_${index}`"
          :class="dateTabNow === index ? 'acitve' : ''" @click="changeDateSearch(index)">{{
              $t('memberDetails.btn.' + item.t)
          }}</div>
      </div>
      <div class="personal_content_wrap" v-if="bottomTabNow === 'personal'">
        <div class="content_item" v-for="item, index in personalTable" :key="index">
          <div class="title">{{ $t(`memberDetails.table.${item.title}`, { Username: ownData.username }) }}</div>
          <div class="money">
            <span>{{ personalData[item.key] || 0 }}</span><span class="unit" v-if="!!item.unit">{{ item.unit === "money"
                ?
                verConfig.moneyType : "%"
            }}</span>
          </div>
        </div>
      </div>
      <div class="personal_content_wrap" v-else>
        <div class="content_item" v-for="item, index in groupTable" :key="index">
          <div class="title">{{ $t(`memberDetails.table.${item.title}`, { Username: ownData.username }) }}</div>
          <div class="money">
            <span>{{ groupData[item.key] || 0 }}</span><span class="unit" v-if="!!item.unit">{{ item.unit === "money"
                ?
                verConfig.moneyType : "%"
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content" v-else>
      <List v-model="loading" :finished="!hasMore" :loading-text="$t('Hint.loading')" :finished-text="$t('Hint.notMore')" @load="getData('children')">
        <ListItem v-for="item, index in listData" :key="index" :data="item" :showToDetails="false" :route="false" />
      </List>
    </div>
  </div>
</template>

<script>
import { Sticky, Tabs, Tab, CellGroup, Cell, List } from 'vant';
import Navbar from "@/components/JNav"
import { post } from '@/utils/newRequest'
import ListItem from "./components/listItem.vue"
import $format from '../../utils/dateFormat';
const getDateNum = (month, year, strict = false) => {
  if (!strict) {
    month += 1;
  }
  const more = [1, 3, 5, 7, 8, 10, 12];
  const less = [4, 6, 9, 11]
  if (more.indexOf(month) !== -1) {
    return 31
  } else if (less.indexOf(month) !== -1) {
    return 30
  } else {
    if (!!year) {
      if ((year % 4 === 0 && year % 100 !== 0) || (year % 100 === 0 && year % 400 === 0)) {
        return 29
      }
    }
    return 28
  }
}
export default {
  components: { Sticky, Tabs, Tab, Navbar, ListItem, CellGroup, Cell, List },
  data() {
    this.personalTable = [
      { title: 'personalBalance', unit: "money", key: "balance" },
      { title: 'childrenNum', key: "child_num" },
      { title: 'rechargeAmount', unit: "money", key: "pay_money" },
      { title: 'withdrawAmount', unit: "money", key: "extract_money" },
      { title: 'betAmount', unit: "money", key: "bet_money" },
      { title: 'winAmount', unit: "money", key: "win_money" },
      { title: 'betCommission', unit: "money", key: "ref_money" }
    ]
    this.groupTable = [
      { title: 'groupBalance', unit: "money", key: "balance" },
      { title: 'groupRegNum', key: "child_num" },
      { title: 'groupBetUsers', key: "bet_users" },
      { title: 'groupBetAmount', unit: "money", key: "bet_money" },
      { title: 'groupWinNum', key: "win_users" },
      { title: 'groupWinAmount', unit: "money", key: "win_money" },
      { title: 'groupRechargeAmount', unit: "money", key: "pay_money" },
      { title: 'groupWithdrawAmount', unit: "money", key: "extract_money" },
      { title: 'groupBetCommission', unit: "money", key: "ref_money" }
    ]
    const nowDate = new Date();
    const year = nowDate.getFullYear();
    const month = nowDate.getMonth() + 1;
    const date = nowDate.getDate();
    const day = nowDate.getDay();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneMonth = (M = month) => {
      return oneDay * getDateNum(M)
    };
    const now = nowDate.getTime();
    const yesterday = now - oneDay;
    const lastMonth = month - 1 > 0 ? month - 1 : 12;
    const lastYear = lastMonth == 12 ? year - 1 : year;
    const lastMonthNum = getDateNum(lastMonth, lastYear, true);
    const lastMonthTime = now - oneMonth(lastMonth);
    this.dateData = [
      { t: "today", value: [$format(now, "yy-MM-dd"), $format(now, "yy-MM-dd")] },
      { t: "yesterday", value: [$format(yesterday, "yy-MM-dd"), $format(yesterday, "yy-MM-dd")] },
      { t: "thisMonth", value: [`${year}-${month}-1`, $format(now, "yy-MM-dd")] },
      { t: "lastMonth", value: [`${$format(lastMonthTime, "yy-MM")}-1`, `${$format(lastMonthTime, "yy-MM")}-${lastMonthNum}`] }
    ]
    return {
      tabNow: 0,
      showToast: true,
      id: 0,
      bottomTabNow: 'personal',
      dateTabNow: 0,
      personalData: {},
      groupData: {},
      ownData: {},
      listData: [],
      page: 0,
      loading: false,
      hasMore: true
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
    this.getPersonal();
  },
  methods: {
    // 日期选择
    changeDateSearch(which) {
      this.dateTabNow = which;
      this.getData(this.bottomTabNow);
    },
    // 切换个人数据或团队数据
    bottomTabChange(which) {
      if (which === this.bottomTabNow) return;
      this.bottomTabNow = which;
      this.dateTabNow = 0;
      this.getData(this.bottomTabNow);
    },
    // 获取数据
    async getData(act = "", params = {}, showToast = true) {
      if (act === "children") {
        this.loading = true;
        this.page += 1;
      }
      if (this.page === 1 && act === "children") {
        this.showToast = true;
      } else {
        this.showToast = false;
      }
      if (act === "personal" || act === "group") {
        const dateItem = this.dateData[this.dateTabNow];
        params.start_day = dateItem.value[0]
        params.end_day = dateItem.value[1]
      }
      try {
        const res = await post("/users/ref/userinfo",
          { act, id: this.id, ...params }
          , { showToast });
        console.log(act, res)
        switch (act) {
          case "":
            this.ownData = res;
            break;
          case "children":
            const { currentPage, lists, hasMorePages } = res;
            this.hasMore = hasMorePages;
            this.loading = false;
            if (currentPage == 1) {
              this.listData = lists;
              return
            }
            this.listData = [...this.listData, ...lists];
            break;
          default:
            this[`${act}Data`] = res;
            break;
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取个人数据
    async getPersonal() {
      this.getData("personal");
    },
    // 获取团队数据
    async getGroup() {
      this.getData("group");
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  min-height: 100vh;
  background: #f5f5f5;

  .tab_wrap {
    background: var(--theme);
    padding: 8px 1px;
    margin-top: -1px;
    display: flex;

    .content_wrap {
      background: rgba(255, 255, 255, .2);
      height: 28px;
      line-height: 28px;
      padding: 2px 0;
      width: 100%;
      @radius: 8px;
      border-radius: @radius;
      display: flex;
      padding: 0 1px;

      .tab_item {
        width: 50%;
        text-align: center;
        color: var(--textColor);
        border-radius: @radius;
      }

      .active {
        color: var(--theme);
        background: #fff;
      }
    }
  }

  .own_money_info_wrap {
    margin-top: 8px;
    padding: 5px;
    background: #fff;

    .item {
      margin: 5px;
      background: #eee;
      padding-left: 6px;
      height: 90px;
      overflow: hidden;

      &:nth-child(1) {
        margin-bottom: 10px;
      }

      .title {
        font-size: 12px;
        color: #999;
        margin-top: 3px;
        margin-bottom: 5px;
      }

      .money {
        padding-left: 6px;
        color: var(--theme);
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  .btns_tabs_wrap {
    background: #fff;
    margin-top: 8px;
    padding: 12px 0;
    display: flex;
    justify-content: center;

    .btn_item {
      padding: 10px 16px;
      border-radius: 4px;
      color: #333;
      background: #CCD0E1;

      &:nth-child(1) {
        margin-right: 8px;
      }
    }

    .active {
      background: var(--theme);
      color: var(--textColor);
    }
  }

  .date_btns_wrap {
    background: #8289B6;
    display: flex;
    justify-content: center;
    padding: 6px;

    .btn_item {
      color: var(--textColor);
      padding: 12px 8px;
      border-radius: 4px;
    }

    .acitve {
      background: var(--theme);
    }
  }

  .personal_content_wrap {
    display: flex;
    flex-wrap: wrap;
    @gap: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    @width: calc(100vw - 10px);

    .content_item {
      width: calc((@width - @gap * 2) / 2);
      background: #eee;
      margin-top: 10px;
      margin-right: 10px;
      height: 90px;
      overflow: hidden;

      .title {
        padding-left: 6px;
        font-size: 12px;
        color: #999;
        margin-top: 3px;
      }

      .money {
        font-size: 24px;
        text-indent: 12px;
        color: var(--theme);
        margin-top: 3px;
        font-weight: 700;
        display: flex;
        align-items: flex-end;

        >.unit {
          font-size: 14px;
          padding-bottom: 4px;
          margin-left: -5px;
        }
      }
    }
  }
}
</style>